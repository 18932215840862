<script setup></script>

<template>
  <div class="sticky top-0 bg-white shadow">
    <div class="flex space-x-4 border-b border-gray-200 w-full text-center">
      <a href="/life-in-turkey" class="py-2 px-4 font-medium w-1/2"
        :class="{ [$route.fullPath == '/life-in-turkey' ? 'text-indigo-900 border-b-2 border-indigo-900' : 'text-gray-500']: true }">Life
        in Turkey</a>
      <a href="/life-in-dubai" class="py-2 px-4 font-medium w-1/2"
        :class="{ [$route.fullPath == '/life-in-dubai' ? 'text-indigo-900 border-b-2 border-indigo-900' : 'text-gray-500']: true }">Life
        in Dubai</a>
    </div>
  </div>
</template>